@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'JetBrains Mono', 'monospace';
}


::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(127, 241, 51);
}

@media screen and (max-width: 499px) {
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #0efa6b 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0efa6b solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }



  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .introduction {
    margin-bottom: 25vh;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 90px;
    color: white;
    text-align: center;
    margin-top: 170px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 7%;
    display: flex;
    justify-content: center;
    letter-spacing: 10px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 350px;
    height: 350px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -250px;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3.5rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 3.5rem;
    line-height: 3.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    background-size: cover;

    /* Fix for Safari */
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    background-size: cover;

    /* Fix for Safari */
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
    background-size: cover;

    /* Fix for Safari */
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
  }

  .boxWrap2Rm {
    padding-bottom: 7%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;

    /* Fix for Safari */
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
  }


  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(20deg);
            transform: perspective(800px) rotateY(20deg);
  }

  .about img {
    width: 80%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-15deg);
            transform: perspective(800px) rotateY(-15deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .about img:hover {
    opacity: 0.3;
  }

  .about img:hover {
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 55px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conT2 {
    font-size: 55px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 25px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 50px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 42px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 3px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 250px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 80px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 20px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 16px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 100%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 800px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -50px;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 18px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -50px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #0efa6b 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0efa6b solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }



  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .introduction {
    margin-bottom: 25vh;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 90px;
    color: white;
    text-align: center;
    margin-top: 170px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 7%;
    display: flex;
    justify-content: center;
    letter-spacing: 35px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 400px;
    height: 400px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -260px;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3.5rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 3.5rem;
    line-height: 3.5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(20deg);
            transform: perspective(800px) rotateY(20deg);
  }

  .about img {
    width: 80%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-15deg);
            transform: perspective(800px) rotateY(-15deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .about img:hover {
    opacity: 0.3;
  }

  .about img:hover {
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 55px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conT2 {
    font-size: 55px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 27px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 15px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 80px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 16px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #0efa6b 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0efa6b solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }



  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .introduction {
    margin-bottom: 25vh;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 150px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 7%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 400px;
    height: 400px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -260px;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(20deg);
            transform: perspective(800px) rotateY(20deg);
  }

  .about img {
    width: 50%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-15deg);
            transform: perspective(800px) rotateY(-15deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }

  .about img:hover {
    opacity: 0.3;
  }

  .about img:hover {
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 16px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 16px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000000c2;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #0efa6b 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0efa6b solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }



  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .introduction {
    margin-bottom: 25vh;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 17%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 40%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -25%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(20deg);
            transform: perspective(800px) rotateY(20deg);
  }

  .about img {
    width: 40%;
    height: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-15deg);
            transform: perspective(800px) rotateY(-15deg);
    margin-top: auto;
    margin-bottom: auto;
  }

  .about img:hover {
    opacity: 0.3;
  }

  .about img:hover {
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 16px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 16px;
    width: 90%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 15vh
  }

  .logo {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px #b0e35ea9, 0 -10px 20px #72f3279f, 0 -18px 40px #64e64aad;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 4.5%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 20px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .about img:hover {
    opacity: 0.3;
  }

  .about img:hover {
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conT2 {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 35px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 10px #def99e;
  }

  .storyCon {
    font-size: 16px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 16px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .btnfos-0-3:hover {
    background-color: rgb(15, 129, 223)
  }

  .btnfos-0-3:active {
    background-color: rgb(15, 129, 223);
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 100px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-shadow: 0 0 10px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 700px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 20px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    border-radius: 10px;
    margin-left: -40px;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 95px;
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 70px;
    height: 70px;
    border: 4px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 8px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 45px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 8px;
    background-color: #c5c3cb8a;
    left: 45px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 5px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 80px;
    height: 80px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 1920px) {

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 22.5px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 55px;
    margin-right: 55px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 25px;
    padding-left: 50px;
    padding-right: 50px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: 90vh;
    min-height: 90vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 30vh;
  }

  .logo {
    width: 140px;
    height: 140px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 150px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -3px 6px #FFF, 0 -4px 12px #b0e35ea9, 0 -12px 22px #72f3279f, 0 -20px 42px #64e64aad;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    letter-spacing: 50px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 6px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 28px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .about img:hover {
    opacity: 0.3;
  }

  .about img:hover {
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 110px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conT2 {
    font-size: 110px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 50px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 20px #def99e;
  }

  .storyCon {
    font-size: 22px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 22px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 50px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 4px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 35px;
    width: 350px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .nftamount {
    color: #02ace0;
    font-size: 120px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 30px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 23px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 28px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 28px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 100px;
    height: 100px;
    margin-left: 35px;
    margin-right: 35px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 400px;
    height: 400px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 23px;
    text-shadow: 0 0 20px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 800px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5%;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 28px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 26px;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5%;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 145px;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 6px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 10px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 65px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    background-color: #c5c3cb8a;
    left: 65px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 6px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 120px;
    height: 120px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 30px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 65px;
    margin-right: 65px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 35px;
    padding-left: 60px;
    padding-right: 60px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: 90vh;
    min-height: 90vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 30vh;
  }

  .logo {
    width: 190px;
    height: 190px;
    border-radius: 100px;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 200px;
    color: white;
    text-align: center;
    margin-top: 13%;
    text-shadow: 0 -5px 6px #FFF, 0 -6px 14px #b0e35ea9, 0 -14px 24px #72f3279f, 0 -22px 44px #64e64aad;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    letter-spacing: 90px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 35%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -23%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 6px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 38px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 4px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-15deg);
            transform: perspective(800px) rotateY(-15deg);
  }

  .about img:hover {
    opacity: 0.3;
  }

  .about img:hover {
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 145px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conT2 {
    font-size: 145px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 65px;
    color: white;
    letter-spacing: 4px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 20px #def99e;
  }

  .storyCon {
    font-size: 30px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .storyCon2 {
    font-size: 30px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 80px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 115px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 35px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 80px;
    margin-right: 80px;
    padding: 5px;
    width: 140px;
    height: 140px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 4px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 50px;
    width: 560px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .nftamount {
    color: #02ace0;
    font-size: 180px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 40px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 30px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 37px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 37px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 120px;
    height: 120px;
    margin-left: 45px;
    margin-right: 45px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 550px;
    height: 550px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 28px;
    text-shadow: 0 0 20px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 800px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5%;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 28px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 26px;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5%;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 145px;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 6px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 10px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 65px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    background-color: #c5c3cb8a;
    left: 65px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 6px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 120px;
    height: 120px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}

@media screen and (min-width: 3840px) {

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 25%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 50px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 95px;
    margin-right: 95px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 30px;
    font-size: 55px;
    padding-left: 80px;
    padding-right: 80px;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    color: white;
    letter-spacing: 5px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #b5ff61;
    color: #f0f7da;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: 90vh;
    min-height: 90vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/

    background-size: cover;
  }

  @-webkit-keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  @keyframes changeBackground {
    0% {
      opacity: 0;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    45% {
      opacity: 1;
      background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106881695011061821/NFTHouse9_inside_the_alien_ship_64k_e3b927d3-3c84-4c33-9008-8a1b46ed3bfc.png') no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    55% {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }

    100% {
      opacity: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.7203256302521008) 0%, rgba(0, 0, 0, 0.2497373949579832) 50%, rgba(0, 0, 0, 0.742734593837535) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
      background-size: cover;
      height: 100vh;
      min-height: 100vh;
      height: -webkit-max-content;
      height: max-content;
      overflow: hidden;
    }
  }

  .introduction {
    margin-bottom: 30vh;
  }

  .logo {
    width: 300px;
    height: 300px;
    border-radius: 100%;
  }

  .logoDiv {
    width: 25%;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 320px;
    color: white;
    text-align: center;
    margin-top: 12%;
    text-shadow: 0 -5px 6px #FFF, 0 -6px 14px #b0e35ea9, 0 -14px 24px #72f3279f, 0 -22px 44px #64e64aad;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    letter-spacing: 120px;
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 35%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -23%;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 8px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #dcf5ae, #b3ee60);
    border-image-slice: 2;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(36, 150, 36);
    background: linear-gradient(90deg, rgba(36, 150, 36, 0.20211834733893552) 0%, rgba(36, 150, 36, 0.1516981792717087) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 60s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 5rem;
    font-size: 50px;
    color: #ffffff;
    text-shadow: 0 0 10px #c5f173;
    letter-spacing: 4px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-bottom: 4px solid #7357ab;
    background-color: #f8d2c6;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 60px;
    color: #7357ab;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .boxWrap2Story {
    margin-top: 7%;
    margin-bottom: 7%;
  }

  .boxWrap2Mint {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://img.freepik.com/free-vector/stage-illuminated-by-spotlights_107791-928.jpg?w=740&t=st=1683982217~exp=1683982817~hmac=0f871c85d3f1a98262cfca6dc3abcff569a607560ea2e5da5691c0e9f2fcefd5') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrapBuy {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.midjourney.com/0f3eea02-df2a-4b26-9887-37299e3f8606/0_2.png') no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url(/static/media/footer.cb0032f7.png) no-repeat center center fixed;
    background-size: cover;
  }

  .boxWrap2Rm {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0.16850490196078427) 50%, rgba(0, 0, 0, 1) 100%), url('https://cdn.discordapp.com/attachments/1008571037862080542/1106998762481659904/NFTHouse9_aliens_go_along_a_path_to_a_alien_planet_through_out__30ec42d2-857e-44dc-bb3a-781330c98189.png') no-repeat center center fixed;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .buyMain {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .step1 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    -webkit-transform-origin: center;

            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .step2 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-25deg);
            transform: perspective(800px) rotateY(-25deg);
  }

  .step3 {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(25deg);
            transform: perspective(800px) rotateY(25deg);
  }

  .about img {
    width: 40%;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transition: 0.5s;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: perspective(800px) rotateY(-10deg);
            transform: perspective(800px) rotateY(-10deg);
  }

  .about img:hover {
    opacity: 0.3;
  }

  .about img:hover {
    -webkit-transform: perspective(800px) rotateY(0deg);
            transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 210px;
    color: white;
    letter-spacing: 6px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  .conT2 {
    font-size: 210px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
  }

  .conT3 {
    font-size: 100px;
    color: white;
    letter-spacing: 4px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    text-shadow: 0 0 20px #def99e;
  }

  .storyCon {
    font-size: 45px;
    width: 55%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;

  }

  .storyCon2 {
    font-size: 45px;
    width: 60%;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 100px;
  }

  .storyCon2 p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }

  .astronaut {
    width: 20%;
    margin-left: 37%;
    margin-top: -25%;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
  }

  @-webkit-keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  @keyframes float {
    0% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }

    50% {
      -webkit-transform: translateX(-50%) translateY(-20px);
              transform: translateX(-50%) translateY(-20px);
    }

    100% {
      -webkit-transform: translateX(-50%) translateY(0);
              transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }


  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
  }


  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 155px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 55px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 80px;
    margin-right: 80px;
    padding: 5px;
    width: 190px;
    height: 190px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 6px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 30px;
    font-size: 70px;
    width: 700px;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 6px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .nftamount {
    color: #02ace0;
    font-size: 250px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 50px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 40px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 60px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 60px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: black 2px solid;
  }

  .box1 {
    background-color: #bbd7fa;
    padding: 30px;
    border-radius: 50px;
    border: black 2px solid;
    transition: box-shadow .2s ease, -webkit-transform .2s ease;
    transition: transform .2s ease, box-shadow .2s ease;
    transition: transform .2s ease, box-shadow .2s ease, -webkit-transform .2s ease;
    /* Add box-shadow transition */
    box-shadow: 0 0 transparent;
    /* Set initial box shadow */
  }

  .box1:hover {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    box-shadow: 10px -10px rgb(140, 0, 255), 0 0 10px rgba(0, 0, 0, 0.3);
    /* Add box shadow for both element and border */
  }

  .name {
    font-family: 'Anton', sans-serif;
    color: rgb(0, 0, 0);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    font-size: 18px;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(0, 0, 0);
    background-color: #fbe8f3;
    border-radius: 100px;
    box-shadow: 0 8px #a88d9d;
    font-weight: 500;
    border: 2px black solid;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(48, 48, 48);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
    text-shadow: 0 0 10px #f8c9b3;

  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 170px;
    height: 170px;
    margin-left: 65px;
    margin-right: 65px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .icons img:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 800px;
    height: 800px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .footer {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
  }

  .copyright {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 45px;
    text-shadow: 0 0 20px #7cb100;
  }

  .faqR {
    margin-top: -60px;
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .os {
    font-family: 'Anton', sans-serif;
    font-size: 35px;
    background-color: #3e96ee;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
  }

  .os:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffffff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .os:hover {
    background-color: transparent;
    color: #0084ff;
  }

  .os:hover:before {
    width: 100%;
  }


  .timeline-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    width: 90%;
  }

  .timeline-container .vertical-scrollable-timeline {
    width: 800px;
    list-style-type: none;
  }

  .timeline-container .vertical-scrollable-timeline li {
    position: relative;
    padding: 35px 0px 125px 158px;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li h2 {
    font-size: 45px;
    margin-bottom: 10px;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5%;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;
  }

  .smallL {
    font-size: 28px;
    letter-spacing: 0px;
    font-style: italic;
    color: #ffffffdc;
  }

  .timeline-container .vertical-scrollable-timeline li p {
    line-height: 1.5;
    color: #fff;
    font-size: 26px;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5%;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .timeline-container .vertical-scrollable-timeline li p:last-child {
    margin-bottom: 0;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 145px;
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: 1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder::before {
    content: "";
    width: 110px;
    height: 110px;
    border: 6px solid #ffffff7a;
    position: absolute;
    background-color: #c5c3cb8a;
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li .icon-holder i {
    font-size: 25px;
    color: #fff;
  }

  .timeline-container .vertical-scrollable-timeline li::after {
    content: "";
    position: absolute;
    height: 0;
    width: 10px;
    background-color: #6b9d3e;
    box-shadow: 0 0 15px #81b800;
    left: 65px;
    top: 34px;
    z-index: 0;
    transition: 0.4s all;
  }

  .timeline-container .vertical-scrollable-timeline li::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 10px;
    background-color: #c5c3cb8a;
    left: 65px;
    z-index: 0;
  }

  .timeline-container .vertical-scrollable-timeline li:last-child::before {
    content: unset;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder {
    background-color: #81b800;
    box-shadow: 0 0 15px #fdee75;
  }

  .timeline-container .vertical-scrollable-timeline li.active .icon-holder::before {
    background-color: #5d9039;
  }

  .timeline-container .vertical-scrollable-timeline li.active::after {
    height: 100%;
  }

  .timeline-container .vertical-scrollable-timeline li.active:last-child::after {
    content: unset;
  }


  figure {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  figure:nth-child(1) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-12.5deg);
            transform: scale(0.6) rotate(-12.5deg);
  }

  figure:nth-child(2) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-9.7222222222deg);
            transform: scale(0.6) rotate(-9.7222222222deg);
  }

  figure:nth-child(3) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-6.9444444444deg);
            transform: scale(0.6) rotate(-6.9444444444deg);
  }

  figure:nth-child(4) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-4.1666666667deg);
            transform: scale(0.6) rotate(-4.1666666667deg);
  }

  figure:nth-child(5) .rmDetails {
    -webkit-transform: scale(0.6) rotate(-1.3888888889deg);
            transform: scale(0.6) rotate(-1.3888888889deg);
  }

  figure:nth-child(6) .rmDetails {
    -webkit-transform: scale(0.6) rotate(1.3888888889deg);
            transform: scale(0.6) rotate(1.3888888889deg);
  }

  figure:nth-child(7) .rmDetails {
    -webkit-transform: scale(0.6) rotate(4.1666666667deg);
            transform: scale(0.6) rotate(4.1666666667deg);
  }

  figure:nth-child(8) .rmDetails {
    -webkit-transform: scale(0.6) rotate(6.9444444444deg);
            transform: scale(0.6) rotate(6.9444444444deg);
  }

  figure:nth-child(9) .rmDetails {
    -webkit-transform: scale(0.6) rotate(9.7222222222deg);
            transform: scale(0.6) rotate(9.7222222222deg);
  }

  figure:nth-child(10) .rmDetails {
    -webkit-transform: scale(0.6) rotate(12.5deg);
            transform: scale(0.6) rotate(12.5deg);
  }

  figure .rmDetails {
    width: 100%;
    border-radius: 3px;
    background-color: black;
    padding: 50px;
    border: 6px solid gray;
  }

  h1 {
    text-align: center;
    margin-bottom: 200px;
    margin-top: 100px;
    font-size: 50px;
    font-weight: 600;
    color: #b80019;
  }

  .footPrint2 {
    width: 120px;
    height: 120px;
  }

  .rmSub {
    color: rgb(231, 231, 231);
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
    margin-left: -40px;

  }

  .rmNote {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }

  .timeline-container p {
    font-size: 18px;
  }

  .rmNote2 {
    font-weight: 500;
    color: rgb(156, 154, 154);
    font-size: 18px;
    font-style: italic;
    margin-left: -40px;
  }

  .stickyBtn2 {
    width: 55px;
    height: 55px;
    z-index: 100000000000000000000;

  }

  #sticky-button {
    position: -webkit-sticky;
    position: sticky;
    position: sticky;
    bottom: 7%;
    margin-top: -10%;
    cursor: pointer;
    z-index: 100000000000000000000;
  }

  .stickyBtn2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
}
@media screen and (max-width: 499px) {

  .carouselIMG {
    width: 250px;
    height: 250px;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

 
  }

  .boxWrapC {
    width: 100%;
    padding-top: 80px;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -20%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .carouselIMG {
    width: 270px;
    height: 270px;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
    padding-top: 80px;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -20%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .carouselIMG {
    width: 300px;
    height: 300px;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
    margin-top: 80px;

  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .carouselIMG {
    width: 320px;
    height: 320px;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
    margin-top: 80px;

  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .carouselIMG {
    width: 320px;
    height: 320px;
     margin-left: 10px;
    margin-right: 10px;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  }

  .boxWrapC {
    width: 100%;
    margin-top: 80px;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1920px) {

  .carouselIMG {
    width: 400px;
    height: 400px;
    margin-left: 10px;
    margin-right: 10px;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }

  .boxWrapC {
    width: 100%;
    margin-top: 80px;

  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 550px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

